'use client'

import { Box, Grid, Hidden, breakpoints } from '@fortum/elemental-ui'

import type { ColumnMenuItemEntry, MainNavigationEntryItem } from '@/shared/contentful/types'
import { rejectNil } from '@/shared/utils/array'

import { FeatureColumn } from './columns/FeatureColumn'
import { LinkColumn } from './columns/LinkColumn'

type CatalogueContainerProps = {
  fields: MainNavigationEntryItem
  visible: boolean
  device: 'desktop' | 'mobile'
}

// Check if column has a titleLink or links
const hasTitleLinkOrLinks = (column: ColumnMenuItemEntry) =>
  Boolean(column.titleLink || column.linksCollection?.items?.length)

export const CatalogueContainer = ({ fields, visible, device }: CatalogueContainerProps) => {
  const isColumnMenu = fields.__typename === 'ColumnMenu'
  // Filter out columns without titleLink or links first to simplify mapping and avoid index errors
  const validColumns = isColumnMenu
    ? rejectNil(
        fields?.columnsCollection?.items?.filter(
          (column) => column && hasTitleLinkOrLinks(column),
        ) || [],
      )
    : []

  const linkColumn = (isDesktop: boolean) =>
    validColumns.map((column, index) => {
      const linkGroups = column.linksCollection?.items
        ? rejectNil(column.linksCollection.items).filter((group) => group.page?.slug || group.url)
        : []

      return (
        <LinkColumn
          titleLink={column.titleLink}
          linkGroups={linkGroups}
          key={column?.name}
          indexColumn={index}
          isDesktop={isDesktop}
        />
      )
    })

  const validFeatureColumn =
    isColumnMenu && fields?.highlightColumn
      ? {
          ...fields.highlightColumn,
          linksCollection: {
            items: rejectNil(fields.highlightColumn.linksCollection?.items || []).filter(
              (link) => link.page?.slug || link.url,
            ),
          },
        }
      : null

  if (device === 'desktop') {
    return (
      <Hidden below="xl">
        <Grid
          display={`${visible ? 'flex' : 'none'}`}
          justifyContent="center"
          flexDirection={{ default: 'column', xl: 'row' }}
          width={`${breakpoints.xl}px`}
          data-testid="main-nav-column-container"
        >
          {linkColumn(true)}
          {validFeatureColumn && <FeatureColumn {...validFeatureColumn} />}
        </Grid>
      </Hidden>
    )
  }
  return (
    <Hidden above="xl">
      <Box
        display={`${visible ? 'flex' : 'none'}`}
        justifyContent="center"
        flexDirection="column"
        width="100%"
      >
        {linkColumn(false)}
        {validFeatureColumn && <FeatureColumn {...validFeatureColumn} />}
      </Box>
    </Hidden>
  )
}
